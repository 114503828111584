<!-- 管理员设置评卷 -->
<template>
  <div>
    <el-row>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="考试状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.examStatus"
            placeholder="请选择考试状态"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examStatus in examStatusOptions"
              :key="examStatus.value"
              :label="examStatus.label"
              :value="examStatus.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="补考状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subExamStatus"
            placeholder="请选择补考状态"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examStatus in subExamStatusOptions"
              :key="examStatus.value"
              :label="examStatus.label"
              :value="examStatus.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="阅卷状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.examMarkStatus"
            placeholder="请选择阅卷状态"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examMarkStatus in examMarkStatusOptions"
              :key="examMarkStatus.value"
              :label="examMarkStatus.label"
              :value="examMarkStatus.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="科目" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subjectType"
            placeholder="请选择考试科目"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examSubject in examSubjectOptions"
              :key="examSubject.value"
              :label="examSubject.label"
              :value="examSubject.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考试名称" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.examName"
            placeholder="请输入考试名称"
            clearable
            style="width: 200px"
            @input="reloadInit"
          ></el-input>
        </el-form-item>

        <el-form-item label="考试时间" class="formItemBoxStyle">
          <div class="block">
            <el-date-picker
              v-model="queryTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="queryDateChange"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="init">查询</el-button>
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>
      <el-table
        v-loading="loading"
        border
        ref="table"
        :key="keyF5"
        :data="examInfoList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        default-expand-all
        row-key="examId"
        :tree-props="{ children: 'examInfoList' }"
      >
        <el-table-column
          prop="examName"
          label="考试名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="examStatus"
          label="考试状态"
          show-overflow-tooltip
          width="180"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.examStatus == 2">等待考试</span>
            <span v-else-if="scope.row.examStatus == 3">正在考试</span>
            <span v-else-if="scope.row.examStatus == 4">考试完成</span>
            <span v-else-if="scope.row.examStatus == 5">等待补考</span>
            <span v-else-if="scope.row.examStatus == 6">正在补考</span>
            <span v-else-if="scope.row.examStatus == 7">考试终止</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="examMarkStatus"
          label="阅卷状态"
          show-overflow-tooltip
          width="180"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.examMarkStatus == 1">等待阅卷</span>
            <span v-else-if="scope.row.examMarkStatus == 2">正在阅卷</span>
            <span v-else-if="scope.row.examMarkStatus == 3">完成阅卷</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="subjectTypes"
          label="科目"
          width="180"
          show-overflow-tooltip
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column
          prop="markTypes"
          label="评卷方式"
          show-overflow-tooltip
          :formatter="customFormatter"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="examStartTime"
          label="考试时间"
          show-overflow-tooltip
          width="180"
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column label="操作" width="400px">
          <template slot-scope="scope">
            <!-- 新增数据检查功能 -->
            <el-button
              v-throttle
              size="small"
              type="primary"
              @click="dataTest(scope.row)"
              >数据检查</el-button
            >

            <!-- 新增图片处理功能 -->
            <el-button
              v-throttle
              size="small"
              type="primary"
              @click="goToPicHandlePage(scope.row)"
              >图片处理</el-button
            >

            <el-button
              v-throttle
              size="small"
              type="primary"
              @click="testBtn(scope.row)"
              >视频处理</el-button
            >
            <!-- <el-button v-throttle 
              size="small"
              type="primary"
              icon="el-icon-setting"
              :disabled="true"
              v-if="scope.row.examStatus>=4"
            >设置</el-button>-->

            <!-- 新增试卷功能 -->
            <el-button v-throttle size="small" type="primary"
              >试卷处理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
    <!-- 分页弹框 -->

    <!-- 对话框是否显示 -->
    <!-- 数据检查中的对话框 -->
    <el-dialog
      v-if="checkDataDialogStatus === 'pendding'"
      title="数据检查"
      :visible.sync="dialogVisible"
      width="600px"
      top="30vh"
      :before-close="handleClose"
    >
      <div style="text-align: center">
        <img src="./img/dataLoading.png" />
      </div>
      <div
        style="
          font-size: 18px;
          text-align: center;
          font-weight: 500;
          margin: 10px 0;
        "
      >
        数据正在检查，请稍后...
      </div>
      <div style="width: 500px; margin: 0 auto; color: #ccc; font-weight: 500">
        数据检查过程会持续一定的时间，你可以点击关闭弹窗，进行其他操作，检查完成会有通知弹窗告知结果
      </div>
      <span slot="footer" class="dialog-footer" style="margin-right: 235px">
        <el-button @click="dialogVisible = false">关闭弹窗</el-button>
      </span>
    </el-dialog>

    <!-- 数据检查后的对话框 -->
    <!-- 当检查结果有错误的时候 -->
    <el-dialog
      v-if="checkDataDialogStatus === 'abnormal'"
      title="数据检查"
      :visible.sync="dialogVisible"
      width="600px"
      top="30vh"
      :before-close="handleClose"
    >
      <!-- 头部 -->
      <div
        style="
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
        "
      >
        <div style="margin-right: 10px">
          <img src="./img/dataAbnormal.png" />
        </div>
        <div>
          <div
            style="
              margin-bottom: 5px;
              text-align: left;
              color: rgb(254, 70, 64);
              font-size: 18px;
            "
          >
            检查完成
          </div>
          <div style="color: #000">可在异常处理补充上传</div>
        </div>
      </div>
      <!-- 中部 -->
      <div style="display: flex; justify-content: space-between; margin: 10px">
        <!-- 数据检查结果与用时 -->
        <div>
          <i class="el-icon-document"></i>
          <span style="color: #000">本次共检查数据：</span
          ><strong>{{ dataTestObj[testExamId].dataCheckCount }}条</strong>
        </div>
        <div>
          <i class="el-icon-time"></i>
          <span style="color: #000">总用时：</span
          ><strong>{{
            transformTimeStamp(dataTestObj[testExamId].dataTestTimes)
          }}</strong>
        </div>
      </div>
      <!-- 中部数据检查的问题 -->
      <div
        ref="dialogContent"
        style="
          background-color: rgb(248, 248, 248);
          border-radius: 5px;
          padding: 10px;
        "
      >
        <div style="margin-bottom: 10px">你检查的数据有如下问题：</div>

        <!-- 问题项 -->
        <div
          v-for="(item, i) in unUploadPicMsgArr"
          :key="i"
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          "
        >
          <img
            style="
              width: 18px;
              object-fit: cover;
              transform: scale(0.8);
              position: absolute;
            "
            src="./img/warnPoint.png"
          />
          <span style="margin-left: 20px"
            >有{{ item.count }}位考生{{ item.name }}</span
          >
          <div
            style="
              overflow: hidden;
              border-bottom: 1px dashed #ccc;
              margin-bottom: 8px;
            "
            :style="{ width: dashLineWidth(item.name) }"
          ></div>
          <span
            v-if="item.type === 'pic'"
            style="
              color: rgb(24, 144, 255);
              cursor: pointer;
              text-decoration: underline;
            "
            @click="goToPicHandlePage(testRowData)"
            >处理问题</span
          >
          <span
            v-if="item.type === 'video'"
            style="
              color: rgb(24, 144, 255);
              cursor: pointer;
              text-decoration: underline;
            "
            @click="testBtn(testRowData)"
            >处理问题</span
          >
        </div>
      </div>

      <span slot="footer" class="dialog-footer" style="margin-right: 175px">
        <el-button @click="testAgain" style="margin-right: 10px" type="primary"
          >重新检查</el-button
        >
        <el-button @click="dialogVisible = false" type="danger"
          >关闭弹窗</el-button
        >
      </span>
    </el-dialog>

    <!-- 数据检查后的对话框 -->
    <!-- 当检查结果无错误的时候 -->
    <el-dialog
      v-if="checkDataDialogStatus === 'normal'"
      title="数据检查"
      :visible.sync="dialogVisible"
      width="600px"
      top="30vh"
      :before-close="handleClose"
    >
      <div style="text-align: center">
        <img src="./img/dataNormal.png" />
      </div>
      <div
        style="
          font-size: 18px;
          text-align: center;
          font-weight: 500;
          margin: 10px 0;
        "
      >
        数据检查完成，未发现问题
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin: 30px;
          padding: 0 30px;
        "
      >
        <!-- 数据检查结果与用时 -->
        <div>
          <i class="el-icon-document"></i>
          <span style="color: #ccc">本次共检查数据：</span
          ><strong>{{ dataTestCount }}条</strong>
        </div>
        <div>
          <i class="el-icon-time"></i>
          <span style="color: #ccc">总用时：</span
          ><strong>{{ transformTimeStamp(dataTestTimes) }}</strong>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="margin-right: 175px">
        <el-button @click="testAgain" style="margin-right: 10px"
          >重新检查</el-button
        >
        <el-button @click="dialogVisible = false">关闭弹窗</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import {
  getExamInfoListApi,
  openMarkApi,
  examineeMsgPage,
  examTimeSession,
  abnormalGetCheckData,
} from '@/api/marking/markingManage.js'
import { getClientInfo } from '@/api/marking/markingClient.js'
import { isEmpty, formatDate } from '@/utils/util'
export default {
  name: 'abnormalDispose',
  data() {
    return {
      loading: false,
      queryTime: '',
      queryParam: {
        examStatus: '',
        examMarkStatus: '',
        subjectType: '',
        examName: '',
        examStartTime: '',
        examEndTime: '',
        subExamStatus: '',
      },
      examInfoList: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      examStatusOptions: [
        // { label: '草稿', value: 1 },
        { label: '等待考试', value: 2 },
        { label: '正在考试', value: 3 },
        { label: '考试完成', value: 4 },
        /*  { label: '等待补考', value: 5 },
         { label: '正在补考', value: 6 },
         { label: '考试终止', value: 7 }, */
      ],
      subExamStatusOptions: [
        // { label: '草稿', value: 1 },
        // { label: '等待考试', value: 2 },
        // { label: '正在考试', value: 3 },
        { label: '等待补考', value: 5 },
        { label: '正在补考', value: 6 },
        { label: '考试完成', value: 4 },
        //  { label: '考试终止', value: 7 },
      ],
      examMarkStatusOptions: [
        { label: '等待阅卷', value: 1 },
        { label: '正在阅卷', value: 2 },
        { label: '完成阅卷', value: 3 },
      ],
      examSubjectOptions: [
        { label: '物理', value: 1 },
        { label: '生物', value: 2 },
        { label: '化学', value: 3 },
      ],
      //刷新表格数据
      keyF5: 1,

      // 数据检查结果
      dialogVisible: false, // 对话框是否显示
      dataTestObj: {}, // 数据检查结果缓存
      testRowData: {}, // 测试结果缓存，用于重新检查按钮触发和处理问题时跳转的参数传递
      testExamId: '', // 记录当前打开弹框的 examid，用于重新检查按钮触发和处理问题时跳转的参数传递
      isDataCheckAgain: 1, // 是否进行重新检查，1表示首次检查，0表示重新检查
      dataTestCount: 0, // 检查数据总量
      dataTestTimes: 0, // 检查数据时长，单位毫秒
      checkDataDialogStatus: 'pendding',
    }
  },
  watch: {
    dialogVisible: function (val) {
      if (!val) {
        this.isDataCheckAgain = 1
      }
    },
  },
  mounted() {
    this.init()
    window.test = this.test
  },
  computed: {
    // 过滤掉没有问题的子项
    unUploadPicMsgArr() {
      if (this.dataTestObj[this.testExamId].dataCheckResult) {
        return this.dataTestObj[this.testExamId].dataCheckResult.filter(
          (item) => item.count !== 0
        )
      }
    },
  },
  methods: {
    init() {
      this.getExamInfoList()
    },
    getExamInfoList() {
      this.loading = true
      getExamInfoListApi(
        this.page.pageIndex,
        this.page.pageSize,
        this.queryParam
      )
        .then((res) => {
          if (res.success) {
            this.examInfoList = []
            this.examInfoList = res.data
            this.examInfoList.forEach((item) => {
              if (!isEmpty(item)) {
                item.isParent = true
                item.isOpen = false
                item.openExam = true
                if (item.markRuleStatus == 2) {
                  item.isOpen = true
                  item.openExam = false
                }
                if (!isEmpty(item.examInfoList)) {
                  item.examInfoList.forEach((from) => {
                    from.isParent = false
                    from.isOpen = false
                    from.openExam = true
                    if (item.markRuleStatus == 2) {
                      from.isParent = true
                    } else {
                      from.openExam = false
                    }
                    if (from.markRuleStatus == 2) {
                      from.isOpen = true
                      from.openExam = false
                    }
                  })
                }
              }
            })
            this.page.totalSize = 0
            this.page.totalSize = res.total
            this.keyF5 == 1 ? (this.keyF5 = 2) : (this.keyF5 = 1)
          } else {
            this.msgError(res.msg)
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    queryDateChange(value) {
      if (value.length > 0) {
        this.queryParam.examStartTime = Date.parse(value[0])
        this.queryParam.examEndTime = Date.parse(value[1])
      }
      this.reloadInit()
    },
    edit() {},
    refresh() {
      this.queryTime = ''
      this.queryParam = {
        examStatus: '',
        subjectType: '',
        examName: '',
        examStartTime: '',
        examEndTime: '',
      }
      this.init()
    },
    reloadInit() {
      this.page.pageIndex = 1
      this.init()
    },
    handleSelectionChange() {},
    handleSizeChange(val) {
      this.page.pageSize = val
      this.page.pageIndex = 1
      this.getExamInfoList()
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.getExamInfoList()
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'subjectTypes':
          if (row.subjectTypes <= 0) {
            return '未知'
          }
          let appendResult = ''
          row.subjectTypes.forEach((item, index) => {
            if (isEmpty(item)) {
              return
            }
            let examSubjectName = ''
            switch (item) {
              case 1:
                examSubjectName = '物理'
                break
              case 2:
                examSubjectName = '生物'
                break
              case 3:
                examSubjectName = '化学'
                break
            }
            appendResult += examSubjectName
            if (index < row.subjectTypes.length - 1) {
              appendResult += ','
            }
          })
          return appendResult
        case 'examStartTime':
          if (isEmpty(row.examStartTime)) {
            return '正在考试'
          }
          return formatDate(new Date(row.examStartTime), 'yyyy-MM-dd hh:mm:ss')
        case 'markTypes':
          switch (row.markTypes[0]) {
            case 1:
              return '考后评分'
            case 2:
              return '现场评分'
            case 3:
              return '直播评分'
            default:
              return '未知'
          }
      }
    },
    //
    testBtn(row) {
      this.$router.push({
        path: `/marking/abnormalDialog?
        examInfo=${JSON.stringify(row)}`,
      })
    },

    // 数据检查功能，等数据检查的接口做好后，再来改变弹框的信息
    dataTest(row) {
      // console.log(row.examId);
      this.testExamId = row.examId // 记录 examId
      this.testRowData = row // 记录 row
      this.dialogVisible = true

      // 这里可以获取考试唯一的id ，根据id 往examObj 添加
      if (!this.dataTestObj[row.examId]) {
        // 进入此 if 表示第一次检查
        this.checkDataDialogStatus = 'pendding'
        this.$set(this.dataTestObj, row.examId, {
          dataTestStatus: 'pendding',
          dataCheckResult: {},
        })
        abnormalGetCheckData(row.examId, this.isDataCheckAgain).then((res) => {
          const { data, code, msg } = res
          if (res.success) {
            // 获取数据判断检查是否不合格
            const {
              unPaperTestAnswerCount, // 未上传试无纸化试卷答案数量
              unReportCount, // 	未上传实验报告
              unMicroscopePictureCount, // 	未上传显微镜图片数量
              unAnswerHtmlImgAddrCount, // 	未上传试卷图片数量
              checkCount, // 检查数据量
              checkDataTimes, // 检查时长，单位毫秒
              unTopCameraCount, // 俯拍镜头
              unFrontCameraCount, // 正拍镜头
              unSideCameraCount, // 侧拍镜头
            } = data
            this.dataTestCount = checkCount
            this.dataTestTimes = checkDataTimes
            if (
              unPaperTestAnswerCount == 0 &&
              unReportCount == 0 &&
              unMicroscopePictureCount == 0 &&
              unAnswerHtmlImgAddrCount == 0
            ) {
              // 检查合格
              this.checkDataDialogStatus = 'normal'
              this.dataTestObj[row.examId].dataTestStatus = 'normal'
            } else {
              // 检查有不合格
              this.checkDataDialogStatus = 'abnormal'
              this.dataTestObj[row.examId].dataTestStatus = 'abnormal'
              this.dataTestObj[row.examId].dataCheckCount = checkCount
              this.dataTestObj[row.examId].dataTestTimes = checkDataTimes
              this.$set(this.dataTestObj[row.examId], 'dataCheckResult', [
                // {
                //   name: '未上传电子试卷答案数量',
                //   count: unPaperTestAnswerCount,
                //   type: 'pic',
                // }, // 未上传试无纸化试卷答案数量
                {
                  name: '未上传电子试卷',
                  count: unReportCount,
                  type: 'pic',
                }, // 	未上传实验报告
                {
                  name: '未上传显微镜图片数量',
                  count: unMicroscopePictureCount,
                  type: 'pic',
                }, // 	未上传显微镜图片数量
                {
                  name: '未上传试卷拍照数量',
                  count: unAnswerHtmlImgAddrCount,
                  type: 'pic',
                }, // 	未上传试卷图片数量
                {
                  name: '未上传摄像头1',
                  count: unTopCameraCount,
                  type: 'video',
                }, // 俯拍镜头
                {
                  name: '未上传摄像头2',
                  count: unFrontCameraCount,
                  type: 'video',
                }, // 正拍镜头
                {
                  name: '未上传摄像头3',
                  count: unSideCameraCount,
                  type: 'video',
                }, // 侧拍镜头
              ])
            }
          } else {
            this.$message.error(msg)
            return
          }
        })
      } else {
        // 进入此 if 说明是已经检查过了,根据数据改变弹框状态
        this.checkDataDialogStatus = this.dataTestObj[row.examId].dataTestStatus
      }
    },
    // 重新检查
    testAgain() {
      this.isDataCheckAgain = 0 // 重新检查
      this.$delete(this.dataTestObj, this.testExamId)
      this.dataTest(this.testRowData)
    },
    // 关闭对话框
    handleClose() {
      this.dialogVisible = false
    },
    // 跳转到照片处理页面
    goToPicHandlePage(row) {
      this.$router.push({
        path: `/marking/picHandleDetail?examInfo=${JSON.stringify(row)}`,
      })
    },
    // 调用该方法可以将毫秒转变成 xx-xx-xx
    transformTimeStamp(time) {
      if (time < 1000) return '00:00:01'
      time = Math.floor(time / 1000)
      let hour = Math.floor(time / 3600)
      let min = Math.floor(time / 60)
      min >= 60 && (min = min % 60)
      let sec = Math.floor(time % 60)
      function testTransform(a) {
        if (a < 10) {
          return String(a).padStart(2, '0')
        }
        return a
      }
      return `${testTransform(hour)}:${testTransform(min)}:${testTransform(
        sec
      )}`
    },
    // 动态设置虚线的宽度
    dashLineWidth(name) {
      let len = name.length
      if (len > 10) return '30%'
      if (len === 10) return '40%'
      if (len < 10) return '45%'
    },
  },
  filters: {
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `subjectType值为${key}`
      }
    },
    dayList(val) {
      return dayjs(val).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>
<style lang="scss" scoped></style>
